// Set variables for Edify Colours
$dark-blue: #141b4d;
$light-blue: #2cccd3;
$text-black: #191919;
$text-grey: #212121;
$text-white: #ffffff;
$warm-grey:#F1EEEB;

.nav-tabs .nav-item .nav-link, .nav-tabs .nav-link {
    color:  black !important;
    background-color: #fff;
    border-radius: 0 !important;
    border: none;
    font-size: large;
    font-weight: bolder;
  }
  
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: $dark-blue !important;
    background-color: #fff;
    border-radius: 0 !important;
    border-bottom: solid $dark-blue;
    font-size: large;
    font-weight: bolder;
  }
  
  .nav-tabs {
    border-bottom: $text-black 1px;
  }
  
  .nav-tabs .nav-link:hover{
    color:$dark-blue !important;
    border-bottom: solid $light-blue;
  }
  
  .tab-content {
   //background-color: $warm-grey;
   padding: 20px 10px;
  }