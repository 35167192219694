.main-content-container {
  background-size: cover;
  background-color: #141b4d;
  background-image: url("../../../public/images/landing-bg.png");
  background-repeat: no-repeat;
  height: 1px;
  min-height: 100vh;
  position: relative;

  .grid-container {
    padding: 16px;
    height: 100%;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .login-container {
    padding: 16px;

    height: 100%;
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .logoContainer {
    margin: 8px;

    .logo-login {
      width: 96px;
      height: auto;
    }
  }

  .loginButtonContainer {
    margin: 8px;
    margin-top: 32px;
  }

  .registerAccountContainer {
    margin: 8px;

    p {
      color: white;
    }

    a {
      color: #2cccd3;
    }
  }
}
